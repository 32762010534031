html, body, #root {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  font-size: 0.16rem;
  overflow: hidden;
}
html, body, div, ul, li, p {
  display: block;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Microsoft YaHei',Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*用户横竖屏切换的时候，禁止字体缩放*/
  -webkit-text-size-adjust: 100%;
}

input {
  outline: none;
  border: none;
  box-sizing: border-box;
}

canvas {
  outline: 0;
  border: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-image: linear-gradient(to bottom, #f9d39e, #e96d70);
}
